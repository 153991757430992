<template>
  <div class="FieldCustomAdvanceSlider">
    <Field
      :disabled="isDisabled"
      :label="label"
      :max="max"
      :maxlength="3"
      :min="min"
      :required="true"
      type="number"
      :value.sync="value"
      @input="clearError" />
    <span>
      {{ errorMsg }}
    </span>
    <Slider
      :id="inputId"
      :disabled="isDisabled"
      :dotSize="100"
      :interval="sliderInterval"
      :marks="[min, max]"
      :max="max"
      :min="min"
      :processStyle="{
        backgroundColor: '#4b6ce6',
      }"
      :railStyle="{
        height: '16px',
      }"
      styleTheme="antd"
      :value="value || min"
      @drag-start="clearError"
      @error="error"
      @update:value="$emit('update:value', $event)" />
  </div>
</template>

<script>
import { randomElementId } from 'views/utils'

const ERROR_TYPE = {
  VALUE: 1,
  INTERVAL: 2,
  MIN: 3,
  MAX: 4,
}

export default {
  components: {
    Slider: () => import(/* webpackChunkName: "slider" */ './Slider'),
  },
  props: {
    min: Number,
    max: Number,

    value: Number,

    sliderInterval: {
      type: Number,
      default: 1,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: String,
  },
  data() {
    return {
      inputId: randomElementId(),
      errorMsg: '',
      errorType: null,
    }
  },
  methods: {
    error(type) {
      if (type === ERROR_TYPE.VALUE) this.errorMsg = 'Nustatykite procentinę reikšmę'
      if (type === ERROR_TYPE.INTERVAL) this.errorMsg = 'Procentinė reikšmė turi būti sveikasis skaičius'
      if (type === ERROR_TYPE.MIN) this.errorMsg = `Mažiausias leistinas avanso dydis yra ${this.min}%`
      if (type === ERROR_TYPE.MAX) this.errorMsg = `Mažiausias leistinas avanso dydis yra ${this.max}%`

      this.errorType = type
    },
    clearError() {
      this.errorType = null
      this.errorMsg = ''
    },
  },
}
</script>

<style lang="scss">
.FieldCustomAdvanceSlider {
  margin-bottom: $grid-unit;

  .vue-slider-rail {
    outline: 1px solid $white;
    outline-offset: 0.3rem;
  }

  // size
  &.sm {
    > .used {
      @include lg {
        font-size: $h4;
        margin-top: 0;
      }
    }
  }
}
</style>
