<template>
  <CardFancy v-bind="$props" class="CardSplit">
    <Row>
      <Column :md="8">
        <slot name="left" />
      </Column>
      <Column :md="4">
        <slot name="right" />
      </Column>
    </Row>
  </CardFancy>
</template>

<script>
import CardFancy from './CardFancy'

export default {
  components: { CardFancy },
  props: {
    icon: String,
    title: String,
  },
}
</script>

<style lang="scss">
.CardSplit {
  .Btn {
    margin-top: 1rem;
    @include md {
      margin-top: 0;
    }
  }
}

</style>
