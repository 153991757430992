<template>
  <Sect class="SelfProfile">
    <Notice v-if="!hasSufficientProfile" class="warning">
      Kad galėtumėte rezervuoti dalį elektrinės, reikia užpildyti likusią asmeninę informaciją. Tai galite padaryti dabar arba rezervuojant elektrinę.
    </Notice>
    <FormValidation @submit="save">
      <FormUser
        :hasPasswordChange="hasPasswordChange"
        :isCompanyAlready="isCompanyAlready"
        type="edit"
        :user="user" />
      <div class="buttons">
        <Btn class="arrow" :isLoading="isSaving" type="submit">
          Atnaujinti
        </Btn>
        <Btn
          v-if="!hasPasswordChange"
          class="Btn link minimalistic changePassword"
          data-cy="changePassword"
          @click="hasPasswordChange = true">
          Keisti slaptažodį
        </Btn>
      </div>
    </FormValidation>
  </Sect>
</template>

<script>
import { actions, clone, getters } from './utils'
import FormUser from './components/forms/FormUser'

export default {
  components: { FormUser },
  data() {
    return {
      hasPasswordChange: false,
      isCompanyAlready: false,
      isSaving: false,
      user: {},
    }
  },
  computed: getters('hasSufficientProfile'),
  methods: {
    async save() {
      try {
        this.isSaving = true
        await this.PATCH_USER(this.user)
        this.isCompanyAlready = this.user.isCompany
        this.SUCCESS('Duomenys atnaujinti')
      } finally {
        this.isSaving = false
      }
    },
    ...actions('PATCH_USER'),
  },
  created() {
    this.user = {
      ...clone(this.authUser),
      passwordMatch: null,
    }
    this.isCompanyAlready = this.user.isCompany

    if (!this.user.country) this.user.country = 'LT'
  },
}
</script>

<style lang="scss">
.SelfProfile {
  .buttons {
    text-align: center;

    .changePassword {
      margin-top: 0.5rem;
    }
  }
}
</style>
