<template>
  <CardDocument
    class="Contract"
    :data-cy="contractCy"
    :document="contract"
    :title="getContractTypeLabel(contract.type)">
    <div slot="status">
      <Set v-if="contract.signedAt" class="lg success" name="Pasirašyta">
        {{ contract.signedAt | date }}
      </Set>
      <Set v-else-if="contract.cancelledAt" class="lg danger" name="Būsena">
        Atšaukta
      </Set>
      <Set v-else class="lg danger" name="Būsena">
        Nepasirašyta
      </Set>
      <!--
      <Column :xs="6" v-if="!contract.signedAt">
        <Btn class="danger" icon="check" @click="sign">
          Pasirašyti
        </Btn>
      </Column>
      -->
    </div>
  </CardDocument>
</template>

<script>
import { camelCase, getters } from '@/views/utils'
import CardDocument from './CardDocument'
import Seen from '../mixins/Seen'
import Set from './Set'

export default {
  mixins: [Seen('contract', 'CONTRACT', true, 2000)],
  components: { CardDocument, Set },
  props: {
    contract: Object,
    project: Object,
    reservation: Object,
  },
  computed: {
    contractCy() {
      const { type, signedAt } = this.contract
      const transformedType = camelCase(type)
      return `contract${transformedType}${signedAt ? 'Signed' : ''}`
    },
    ...getters('getContractTypeLabel'),
  },
}
</script>

<style lang="scss">
.Contract {
  padding: 1rem 2rem;
}
</style>
