<script>
import { actions, getters } from 'views/utils'
import PaymentModal from 'components/PaymentModal'

export default {
  components: { PaymentModal },
  data() {
    return {
      isPaymentModalVisible: false,
      paymentInModal: null,
    }
  },
  computed: getters(),
  methods: {
    openPaymentModal(payment) {
      this.isPaymentModalVisible = true
      this.paymentInModal = payment
    },
    closePaymentModal() {
      this.isPaymentModalVisible = false
    },
    ...actions(
      'REDIRECT_TO_PAYSERA',
      'PROMPT',
    ),
  },
}
</script>
