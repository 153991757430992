<template>
  <div v-if="reservation" id="Reservation">
    <div class="title">
      <h2>Rezervacija (<span data-cy="capacity">{{ reservation.capacity }}</span> kW)</h2>
      <div class="subtitle">
        {{ project.name }}
      </div>
    </div>
    <Breadcrumbs
      creationBreadcrumbKey="INFO"
      :reservationId="reservationId" />
    <CardSplit v-if="paymentUnpaid" class="lg danger" icon="order">
      <div slot="left">
        Nesumokėtas {{ paymentUnpaid.name.toLowerCase() }} ({{ paymentUnpaid.amountTotal | currency }}). Privalu jį sumokėti iki {{ paymentUnpaid.deadlineAt | dateTime }}. {{ paymentUnpaidMessage }}
      </div>
      <div slot="right">
        <Btn class="danger" @click="openPaymentModal(paymentUnpaid)">
          Atlikti apmokėjimą
        </Btn>
      </div>
    </CardSplit>
    <FormSect title="Pagrindiniai dokumentai">
      <Empty v-if="!contracts.length">
        Dar nėra paruoštų sutarčių. Sutarties generavimas gali užtrukti iki minutės.
      </Empty>
      <div>
        <Contract
          v-for="contract in contracts"
          :key="contract.id"
          :contract="contract"
          :project="project"
          :reservation="reservation" />
      </div>
      <div>
        <Payment
          v-for="payment in paymentsNonMaintenance"
          :key="payment.id"
          :payment="payment"
          :project="project"
          :reservation="reservation" />
      </div>
      <div>
        <ProjectDocument
          v-for="projectDocument in projectDocuments"
          :key="projectDocument.id"
          :projectDocument="projectDocument" />
      </div>
    </FormSect>
    <FormSect v-if="paymentsMaintenance.length" title="Metinis priežiūros mokestis">
      <Payment
        v-for="payment in paymentsMaintenance"
        :key="payment.id"
        :payment="payment"
        :project="project"
        :reservation="reservation" />
    </FormSect>
    <!--
    <Wrap class="sm">
      <Btn
        v-if="(!payments.length || (payments.length === 1 && paymentUnpaid)) &&
          !reservation.isCancelled"
        class="danger inv"
        icon="delete"
        @click="PROMPT({
          confirm: cancel,
          text: 'Ar tikrai norite nutraukti šį užsakymą?',
        })">
        Atšaukti užsakymą
      </Btn>
    </Wrap>
    -->
    <PaymentModal
      :isOpen.sync="isPaymentModalVisible"
      :payment="paymentInModal"
      :reservation="reservation"
      @close="closePaymentModal" />
  </div>
</template>

<script>
import { actions, getters } from './utils'
import Breadcrumbs from './components/Breadcrumbs'
import CardSplit from './components/CardSplit'
import Contract from './components/Contract'
import Payment from './components/Payment'
import PaymentModals from './mixins/PaymentModals'
import ProjectDocument from './components/ProjectDocument'

export default {
  mixins: [PaymentModals],
  components: {
    Breadcrumbs,
    CardSplit,
    Contract,
    Payment,
    ProjectDocument,
  },
  computed: {
    reservationId() {
      return this.reservation.id
    },
    contracts() {
      return this.query('contract', {
        isHidden: false,
        reservationId: this.reservation.id,
      })
    },
    paymentsMaintenance() {
      return this.query('payment', {
        reservationId: this.reservation.id,
        type: 'MAINTENANCE',
        $sort: { id: -1 },
      })
    },
    paymentsNonMaintenance() {
      return this.query('payment', {
        reservationId: this.reservation.id,
        type: { $ne: 'MAINTENANCE' },
      })
    },
    paymentUnpaid() {
      return this.getReservationUnpaidPayment(this.reservation)
    },
    paymentUnpaidMessage() {
      return {
        RESERVATION: 'Sumokėdami rezervacinį mokestį, sutinkate su sąlygomis užsakymo sutartyje (nurodyta žemiau) ir tai laikoma Jūsų sutarties pasirašymu.',
        ADVANCE: 'Sumokėdami avansinį mokestį, sutinkate su sąlygomis užsakymo sutartyje (nurodyta žemiau) ir tai laikoma Jūsų sutarties pasirašymu.',
      }[this.paymentUnpaid.type] || ''
    },
    project() {
      return this.query('project', this.reservation.projectId)
    },
    projectDocuments() {
      if (!this.shouldSeeDocuments) return []

      return this.query('projectDocument', {
        projectId: this.reservation.projectId,
      })
    },
    reservation() {
      return this.query('reservation', this.$route.params.id)
    },
    shouldSeeDocuments() {
      const paymentMain = this
        .query('payment', {
          reservationId: this.reservation.id,
          type: 'MAIN',
        }, { isSingle: true })

      return !!(paymentMain && paymentMain.paidAt)
    },
    ...getters('getReservationUnpaidPayment'),
  },
  methods: {
    // async cancel() {
    //   await this.CANCEL_RESERVATION(this.reservation)
    // },
    ...actions('LOAD_PROJECT_FILES'),
  },
  mounted() {
    this.LOAD_PROJECT_FILES(this.reservation.projectId)
  },
}
</script>

<style lang="scss">
#Reservation {
  > .Breadcrumbs {
    margin-top: 3rem;
  }

  > .title {
    margin: 4rem 0;
    text-align: center;

    > h2 {
      margin-bottom: 0;
    }

    > .subtitle {
      font-size: $h4;
    }
  }

  .FormSect {
    @include lg {
      border: 0;
    }
  }
}
</style>
