<template>
  <div id="SelfService">
    <Hero src="../../assets/selfService/hero.jpg">
      <h1>Savitarna</h1>
    </Hero>
    <router-view class="self-service-view" />
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer'
import Hero from '../components/landing/Hero'

export default {
  components: { Footer, Hero },
  // methods: actions('FETCH_STATE'),
  // beforeMount() {
  //   this.LOAD(() => {
  //     if (!this.isAuth) {
  //       return this.$router.push({ name: 'Login' })
  //     }

  //     return this.FETCH_STATE()
  //   })
  // },
  mounted() {
    if (this.$route.name === 'SelfServiceLayout') {
      this.$router.replace({ name: 'SelfProjects' })
    }
  },
}
</script>

<style lang="scss">
#SelfService {
  min-height: 100vh;

  > .self-service-view {
    min-height: 60vh;
    padding-bottom: 4rem;
    padding-top: 2rem;
    @include sm {
      padding-top: 4rem;
    }
  }

  > .Hero {
    // background-image:
    //   url('../../assets/landing/savitarna-header.png'),
    //   linear-gradient(#5f37d5, #6e91e5);
    min-height: 15rem;
    @include lg {
      min-height: 20rem;
    }

    h1 {
      margin-top: 4rem;
      text-align: center;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  > #Footer {
    margin-top: 6rem;
    @include md {
      margin-top: 10rem;
    }
  }
}
</style>
