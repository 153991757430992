<template>
  <Card class="DataPoint">
    <Set
      class="lg"
      :icon="icon"
      :name="label">
      {{ value }}
    </Set>
  </Card>
</template>

<script>
import Set from './Set'

export default {
  components: {
    Set,
  },
  props: {
    icon: String,
    label: String,
    value: [Number, String],
  },
}
</script>

<style lang="scss">
.Card.DataPoint {
  border-radius: $radius-md;
  padding: $grid-unit;

  .Set {
    margin-bottom: 0.25rem;

    .SetIcon {
      font-size: $h2;
    }
  }
}
</style>
