import { actions } from 'views/utils'
import { timeout } from 'views/utils/async'

export default (entityKey, serviceName, triggerOnMounted, timeoutMs) => {
  if (!serviceName) serviceName = entityKey.toUpperCase()

  const actionName = `PATCH_${serviceName}`

  return {
    data() {
      return {
        wasSeen: true,
      }
    },
    methods: {
      async setSeen() {
        this.wasSeen = false

        try {
          // delay to prevent method call before record has been created and transaction closed
          await timeout(timeoutMs)

          return await this[actionName]({
            id: this[entityKey].id,
            isSeen: true,
          })
        } catch (error) {
          error.ignore()
        }
      },
      ...actions(actionName),
    },
    mounted() {
      if (triggerOnMounted) {
        this[entityKey].isSeen === false && this.setSeen()
      }
    },
  }
}
