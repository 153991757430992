<template>
  <Wrap class="sm">
    <CardFancy
      v-if="payment"
      data-cy="paymentMessage"
      :title="statusMessage">
      {{ statusExplanation }}
      <template v-if="hasContactButton">
        Administratoriai buvo informuoti apie šią problemą.
      </template>
      <div slot="actions" class="padded">
        <Row>
          <Column :md="hasContactButton ? 6 : 12">
            <Route
              class="Btn"
              :to="{
                name: 'Reservation',
                params: {
                  id: payment.reservationId,
                  projectId: payment.projectId,
                },
              }">
              Grįžti į rezervacijos puslapį
            </Route>
          </Column>
          <Column v-if="hasContactButton" :md="6">
            <Route class="Btn danger" to="Contact">
              Susisiekite
            </Route>
          </Column>
        </Row>
      </div>
    </CardFancy>
  </Wrap>
</template>

<script>
import { actions, getters } from './utils'
import CardFancy from './components/CardFancy'
import { softThrow } from '../bugsnag'

export default {
  components: { CardFancy },
  computed: {
    hasContactButton() {
      return ['ADDITIONAL', 'BAD'].includes(this.paymentOrderStatus)
    },
    isValid() {
      if (!this.response) return false

      try {
        return [
          Math.round(this.payment.amountTotal * 100) === Number(this.response.amount),
          this.authUser.email === this.response.p_email,
          !!this.$route.query.ss1,
          !!this.$route.query.ss2,
        ].every(Boolean)
      } catch {
        return false
      }
    },
    payment() {
      const { orderid: orderId } = this.response || {}

      if (!orderId) return

      const paymentId = Number((orderId || '').split('-')[0])

      return this.query('payment', paymentId)
    },
    paymentOrderStatus() {
      if (!this.isValid) return 'BAD'

      return [
        'NOT',
        'PAID',
        'ACCEPTED',
        'ACCEPTED',
        // 'ADDITIONAL',
      ][this.response.status] || null
    },
    response() {
      try {
        return this.decodeResponse(this.$route.query.data)
      } catch (error) {
        softThrow(error)
        return false
      }
    },
    statusMessage() {
      return {
        null: 'Tikrinama...🙂',
        BAD: 'Nepavyko suprasti Payseros pranešimo 😣',
        NOT: 'Mokėjimas nebuvo atliktas 🙄',
        PAID: 'Mokėjimas atlikas! 🤗',
        ACCEPTED: 'Mokėjimas priimtas! 😊',
        ADDITIONAL: 'Apmokėjimui reikia daugiau informacjos 🤔',
      }[this.paymentOrderStatus]
    },
    statusExplanation() {
      const paymentAmount = this.$root.$options.filters.currency(this.payment.amountTotal)

      return {
        null: 'Palaukite, tai turėtų užtrukti kelias sekundes',
        BAD: 'Sistema susidūrė su neįprasta problema. Informavome administratorius apie ją.',
        NOT: `Atrodo atšaukėte apmokėjimą. Ne bėda, jį galima atlikti ir vėliau. Šį mokėjimą turite atlikti iki ${this.$root.$options.filters.dateTime(this.payment.deadlineAt)}`,
        PAID: `Puiku! Jūsų ${paymentAmount} apmokėjimas gautas. Kviečiame toliau naudotis Saulės Bendruomenės paskyros paslaugomis ir stebėti projekto eigą. Dėl tolimesnių žingsnių susisieksime žinute savitarnoje ir el. pašto pranešimu.`,
        ACCEPTED: 'Apmokėjimas priimtas! Nors ir gavome pranešimą, kad atlikote apmokėjimą, mums dar gali užtrukti iki 1 darbo dienos gauti patvirtinimą apie atliktą apmokėjimą.',
        ADDITIONAL: 'Keista. Paysera paprašė daugiau asmeninės informacijos apmokėjimo atlikimui. To neturėtų būti. Prašytume pabandyti atlikti apmokėjimą kitu būdu. Pavyzdžiui kitu banku ar kortele. Jeigu jau tai pabandėte, prašome susisiekti su mumis paspaudus mygtuką žemiau.',
      }[this.paymentOrderStatus]
    },
    ...getters('decodeResponse', 'isPayseraResponseValid'),
  },
  methods: {
    async verify() {
      const { data, ss1, ss2 } = this.$route.query

      try {
        await this.CREATE_PAYSERA({
          data,
          ss1,
          ss2,
        })
      } catch (error) {
        softThrow(error)
      }
    },
    ...actions('CREATE_PAYSERA'),
  },
  watch: {
    isValid: {
      immediate: true,
      handler(isValid) {
        if (isValid) this.verify()
      },
    },
  },
}
</script>

<style lang="scss">
</style>
