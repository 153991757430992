import { groupByKey } from 'views/utils'
import { head } from 'rambda'

export const formatPlantStats = (() => {
  const toDataPoint = ({ dailyEnergy, power }) =>
    dailyEnergy || power || 0

  const dedupeBy = (key, plantStats) => Object
    .values(groupByKey(key, plantStats))
    .map(head)

  const sliceNonZeroRange = (plantStats) => {
    const indexStart = plantStats
      .findIndex(toDataPoint)
    const indexEnd = [...plantStats]
      .reverse()
      .findIndex(toDataPoint)

    return plantStats
      .slice(indexStart, plantStats.length - indexEnd)
  }

  return (toLabel, plantStatsWeighted, chart, doShowOnlyRelevantData) => {
    const plantStatsUnique = dedupeBy('recordedAt', plantStatsWeighted)
    const plantStatsRelevant = doShowOnlyRelevantData
      ? sliceNonZeroRange(plantStatsUnique)
      : plantStatsUnique
    const values = plantStatsRelevant.map(toDataPoint)

    return {
      ...chart,
      dataSets: chart
        .dataSets
        .map(dataSet => ({ ...dataSet, values })),
      labels: plantStatsRelevant
        .map(toLabel),
    }
  }
})()
