export default (plantStatsSharedByReservationsWeighted) => {
  if (!plantStatsSharedByReservationsWeighted || !plantStatsSharedByReservationsWeighted.length) return

  if (plantStatsSharedByReservationsWeighted.length === 1) return plantStatsSharedByReservationsWeighted[0].plantStats

  const plantStatsSharedByReservationFirst = plantStatsSharedByReservationsWeighted[0]

  const plantStatsSharedByReservationsWithoutFirst = plantStatsSharedByReservationsWeighted
    .filter(plantStatsSharedByReservation => plantStatsSharedByReservation.reservationId !== plantStatsSharedByReservationFirst.reservationId)

  // only leave the stats
  const plantStatsSharedByReservationsWithoutFirstOnlyStats = plantStatsSharedByReservationsWithoutFirst.flatMap(item => item.plantStats)

  return plantStatsSharedByReservationFirst.plantStats
    .map(plantStatInFirst => {
      const plantStatsWithSameId = plantStatsSharedByReservationsWithoutFirstOnlyStats
        .filter(onlyStat => onlyStat.id === plantStatInFirst.id)

      if (!plantStatsWithSameId || plantStatsWithSameId.length < 1) return plantStatInFirst

      const powerSum = plantStatsWithSameId.reduce((previousValue, currentValue) => (Number((previousValue + currentValue.power).toFixed(3))), Number(plantStatInFirst.power))
      const dailyEnergySum = plantStatsWithSameId.reduce((previousValue, currentValue) => (Number((previousValue + currentValue.dailyEnergy).toFixed(3))), Number(plantStatInFirst.dailyEnergy))

      return {
        ...plantStatInFirst,
        power: powerSum,
        dailyEnergy: dailyEnergySum,
      }
    })
}
