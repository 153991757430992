export default (project, plantStatsRaw) => {
  const { plantDataShareBreakpoints } = project
  const projectPlantStats = plantStatsRaw.filter(plantStat => plantStat.plantId === project.plantId)

  if (!plantDataShareBreakpoints || plantDataShareBreakpoints.length < 1) {
    return {
      projectId: project.id,
      plantStatsCalculated: projectPlantStats,
    }
  }

  const plantStatsBeforeFirstBreakpoint = projectPlantStats
    .filter(projectPlantStat => Date.parse(projectPlantStat.recordedAt) < Date.parse(plantDataShareBreakpoints[0].startAt))

  const plantStatsShared = plantDataShareBreakpoints.flatMap((plantDataShareBreakpoint, index) => {
    const dateFrom = Date.parse(plantDataShareBreakpoint.startAt)
    const rate = Number(plantDataShareBreakpoint.rate)
    const nextBreakpoint = plantDataShareBreakpoints[index + 1]

    const plantStatsWithinBreakpoint = projectPlantStats
      .filter(projectPlantStat => {
        const recordedAt = Date.parse(projectPlantStat.recordedAt)
        return nextBreakpoint
          ? recordedAt >= dateFrom && recordedAt < Date.parse(nextBreakpoint.startAt)
          : recordedAt >= dateFrom
      })

    return plantStatsWithinBreakpoint.map(plantShareWithinBreakpoint => {
      return {
        ...plantShareWithinBreakpoint,
        power: (multiplyToPrecision(3, plantShareWithinBreakpoint.power, rate) / 100) || 0,
        dailyEnergy: (multiplyToPrecision(3, plantShareWithinBreakpoint.dailyEnergy, rate) / 100) || 0,
      }
    })
  })

  return {
    projectId: project.id,
    plantStatsCalculated: [
      ...plantStatsBeforeFirstBreakpoint,
      ...plantStatsShared,
    ],
  }
}

const multiplyToPrecision = (precision, a, b) => {
  a = Number(a) || 0
  b = Number(b) || 0

  return Number((a * b).toFixed(precision))
}
