<template>
  <Card class="Chart" :title="title">
    <slot name="head" />
    <Frappe
      ref="frappe"
      :id="id"
      v-bind="$attrs"
      :axisOptions="{
        xAxisMode: 'tick',
        xIsSeries: true,
      }"
      :colors="['#4b6ce6', '#4b6ce6', '#4b6ce6']"
      :dataSets="dataSets"
      :discreteDomains="false"
      :height="height"
      :labels="labels"
      :lineOptions="{
        heatline: 1,
        regionFill: 1,
      }"
      type="axis-mixed" />
  </Card>
</template>

<script>
import { VueFrappe as Frappe } from 'vue2-frappe'
import { randomElementId } from 'views/utils'

export default {
  components: {
    Frappe,
  },
  props: {
    dataSets: Array,
    height: { type: Number, default: 400 },
    labels: Array,
    title: String,
  },
  data() {
    return {
      id: randomElementId(),
    }
  },
}
</script>

<style lang="scss">
.Card.Chart {
}
</style>
