<template>
  <CardDocument
    class="Payment"
    :data-cy="paymentCy"
    :document="payment"
    icon="euro"
    :title="paymentName">
    <Row slot="status">
      <Column xs="auto">
        <Set class="lg" name="Suma">
          <span data-cy="paymentAmount">
            {{ payment.amountTotal | currency }}
          </span>
        </Set>
      </Column>

      <Column xs="auto">
        <Set
          v-if="payment.paidAt"
          class="lg success"
          name="Sumokėta">
          {{ payment.paidAt | date }}
        </Set>
        <Set
          v-else
          class="lg bold"
          name="Sumokėti iki">
          <strong>
            {{ payment.deadlineAt | dateTime }}
          </strong>
        </Set>
      </Column>
    </Row>

    <template v-if="!payment.paidAt" slot="actions">
      <Tag v-if="payment.cancelledAt" class="danger ib">
        Mokėjimas atšauktas
      </Tag>
      <Btn
        v-if="!payment.cancelledAt"
        class="danger"
        data-cy="pay"
        @click="openPaymentModal(payment)">
        Sumokėti
      </Btn>
    </template>

    <PaymentModal
      :isOpen.sync="isPaymentModalVisible"
      :payment="paymentInModal"
      :reservation="reservation"
      @close="closePaymentModal" />
  </CardDocument>
</template>

<script>
import CardDocument from './CardDocument'
import PaymentModals from 'mixins/PaymentModals'
import Seen from 'mixins/Seen'
import Set from './Set'
import { getters } from 'views/utils'

export default {
  mixins: [Seen('payment', 'PAYMENT', true, 2000), PaymentModals],
  components: { CardDocument, Set },
  props: {
    payment: Object,
    project: Object,
    reservation: Object,
  },
  computed: {
    paymentName() {
      if (this.payment.type === 'MAINTENANCE') {
        return [
          this.getPaymentTypeLabel(this.payment.type),
          `(${this.payment.createdAt.getFullYear()})`,
        ].join(' ')
      }

      return this.getPaymentTypeLabel(this.payment.type)
    },
    paymentCy() {
      const { type, paidAt } = this.payment
      const transformedType = type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
      return `payment${transformedType}${paidAt ? 'Paid' : ''}`
    },
    ...getters('getPaymentTypeLabel'),
  },
}
</script>

<style lang="scss">
.Payment {
  // .actionsInline {
  //   display: inline-block;
  // }
}
</style>
