<template>
  <Card
    class="CardFancy Message"
    :class="{ open: isOpen }"
    @click.native="!isOpen && open(true, $event)">
    <div class="body">
      <Row>
        <Column :lg="6">
          <div class="Message-title">
            {{ message.subject }}
            <Tag v-if="!message.isSeen" class="danger round">
              !
            </Tag>
          </div>
        </Column>
        <Column :lg="3" :xs="0" />
        <Column :lg="3" :md="6">
          <Btn
            class="sm"
            @click="open(!isOpen)">
            {{ isOpen ? 'Uždaryti' : 'Skaityti' }}
          </Btn>
        </Column>
      </Row>

      <div v-if="isOpen" class="Message-text">
        <div
          v-if="message.textHtml"
          class="content"
          v-html="message.textHtml" />
        <pre v-else>{{ message.text }}</pre>
        <Route
          v-if="message.reservationId"
          :id="message.reservationId"
          class="Btn inv sm ib"
          to="Reservation">
          Peržiūrėti užsakymą
        </Route>
        <!--
        <Btn
          class="sm danger inv ib"
          @click="DELETE_MESSAGE(message)">
          Trinti
        </Btn>
        -->
      </div>
    </div>
  </Card>
</template>

<script>
import { actions, getters } from '../utils'
import CardFancy from './CardFancy'
import Seen from '../mixins/Seen'

export default {
  mixins: [CardFancy, Seen('message', 'MESSAGE', false, 0)],
  props: {
    message: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    reservation() {
      if (!this.message.reservationId) return

      return this.query('reservation', this.message.reservationId)
    },
    ...getters(),
  },
  methods: {
    open(isOpen = true, event) {
      if (event && event.target.className.includes('Btn')) return

      // await new Promise(resolve => setTimeout(resolve, 100))
      this.isOpen = isOpen

      if (isOpen && this.message.isSeen === false) {
        this.setSeen()
      }
    },
    ...actions('DELETE_MESSAGE'),
  },
}
</script>

<style lang="scss">
.CardFancy.Message {
  cursor: pointer;

  > .body {
    padding: 0;
  }

  .Message-title {
    font-size: $h3;
    font-weight: $semibold;
    margin-bottom: 1rem;
    @include md {
      margin-bottom: 0;
    }

    > .Tag {
      vertical-align: middle;
    }
  }

  .Message-text {
    margin-top: 1rem;

    > pre, > .content {
      font-family: inherit;
      font-size: $h5;
      line-break: normal;
      margin-bottom: 1rem;
      white-space: pre-wrap;
    }

    .Btn {
      margin-right: 1rem;
    }
  }

  &.open {
    cursor: default;
  }
}
</style>
