<template>
  <div class="TabsHead">
    <div
      v-for="({ name, props, value }) in formatedTabs"
      :key="value"
      v-bind="props"
      class="tab"
      :class="{ active: (active || {}).value === value }"
      @click="$emit('update:active', value)"
      @keydown.enter="$emit('update:active', value)">
      <span>
        {{ name }}
      </span>
      <slot :name="value" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Object,
    tabs: Array,
  },
  computed: {
    formatedTabs() {
      if (typeof this.tabs[0] === 'object') return this.tabs

      return this.tabs
        .map(name => ({
          name,
          value: name,
        }))
    },
  },
}
</script>

<style lang="scss">
$_res-xs-under: 1px;

.tab {
  color: $text-color-light;
  cursor: pointer;
  display: inline-block;
  font-weight: $semibold;
  margin: 0;
  padding: 0 0.25rem;
  position: relative;
  @include lg {
    border-right-width: 2px;
    font-size: $h3;
    padding: 0 0.5rem;
  }
  @media screen and (max-width: $_res-xs-under) {
    padding: 0 0.25rem;
  }

  &::after {
    background: $border-color;
    content: ' ';
    display: block;
    height: 50%;
    position: absolute;
    right: 0;
    top: 25%;
    width: 1px;
    @include lg {
      height: 100%;
      top: 0;
      width: 2px;
    }
  }

  > span {
    display: inline-block;
    line-height: $lh * 1.5;
    @include lg {
      font-size: $h3;
      padding: 0 0.5rem;
    }
  }

  &:hover {
    color: $main;
  }

  &:last-of-type {
    border-right: 0;

    &::after {
      display: none;
    }
  }

  &.active {
    color: $text-color-black;

    > span {
      border-bottom-color: $main;
    }
  }
}

.TabsHead {
  &.center {
    text-align: center;
  }
}
</style>
