<template>
  <div id="ReservationStats">
    <StatUserEmail
      v-if="hasRole('>=ADMIN')"
      :email.sync="userSelectedEmail" />
    <ReservationSelect
      :reservations="reservationsActive"
      :value.sync="reservationIdsSelected" />
    <Row>
      <Column
        v-for="keyPoint in keyPoints"
        :key="keyPoint.label"
        :lg="12 / keyPoints.length">
        <DataPoint
          :icon="keyPoint.icon"
          :label="keyPoint.label"
          :value="keyPoint.value" />
      </Column>
    </Row>
    <Row>
      <!-- <Column :lg="6">
        <Chart
          class="realTime"
          :dataSets="shortTerm.dataSets"
          :height="408"
          :labels="shortTerm.labels"
          title="Trumpalaikė"
          :tooltipOptions="{
            formatTooltipY: formatReadingPower,
          }"
          :yMarkers="longTerm.yMarkers">
          <ChartShortTerm
            slot="head"
            :doShowOnlyRelevantData.sync="doShowOnlyRelevantData"
            :shortTermSelected.sync="shortTermSelected" />
        </Chart>
      </Column> -->
      <Column>
        <Chart
          v-if="longTerm.labels.length"
          :dataSets="longTerm.dataSets"
          :labels="longTerm.labels"
          title="Statistika"
          :tooltipOptions="{
            formatTooltipY: formatReadingPowerTotal,
          }"
          :yMarkers="longTerm.yMarkers">
          <ChartYearMonth
            slot="head"
            :earliestAt="earliestAt"
            :endAt.sync="endAt"
            :startAt.sync="startAt"
            :timeframe.sync="timeframe" />
        </Chart>
      </Column>
    </Row>
    <div class="disclosure">
      Pateikiami duomenys gali nesutapti su ESO pateikiamais duomenimis, nes inverterio rodmenys ne visada sutampa su ESO apskaitos skaitliuku.
    </div>
  </div>
</template>

<script>
import {
  actions,
  endOfMonth,
  getters,
  groupByKey,
  intoSum,
  startOfDay,
  startOfMonth,
} from 'views/utils'
import { commaDecimal, date as monthDate } from 'views/utils/filters'
import { pathEq, prop, propOr } from 'rambda'
import { toCapacitiesByDay, toCapacitiesByMoment } from './capacitiesBy'
import Chart from '../components/Chart'
// import ChartShortTerm from '../components/ChartShortTerm'
import ChartYearMonth from '../components/ChartYearMonth'
import DataPoint from '../components/DataPoint'
import ReservationSelect from '../components/ReservationSelect'
import StatUserEmail from '../components/StatUserEmail'
import combinePlantStats from './combinePlantStats'
import { formatPlantStats } from './formatPlantStats'
import { getRelativeDate } from './utils'
import { pluckId } from 'views/utils/collections'
import toPlantStatsSharedByProjects from './toPlantStatsSharedByProjects'

export default {
  components: {
    Chart,
    // ChartShortTerm,
    ChartYearMonth,
    DataPoint,
    ReservationSelect,
    StatUserEmail,
  },
  data() {
    const now = getRelativeDate()
    const yesterday = getRelativeDate(-24 * 60 * 60 * 1000)

    return {
      shortTermSelected: {
        timeframe: 'TODAY',
        recordedAt: {
          $gte: startOfDay(now),
          $lte: now,
        },
      },

      doShowOnlyRelevantData: true,

      // select user (as >=ADMIN)
      userSelectedEmail: null,

      // select date
      timeframe: 'MONTH',
      startAt: startOfMonth(yesterday),
      endAt: endOfMonth(yesterday),

      // select reservations
      reservationIdsSelected: [],

      // fetched separately
      plantStatTotals: [],

      shortTerm: {
        dataSets: [{
          name: 'Sugeneruota galia',
          chartType: 'line',
          values: [],
        }],
        labels: [],
        yMarkers: [
          {
            label: '',
            value: 0,
            type: 'solid',
          },
        ],
      },
      longTerm: {
        dataSets: [{
          name: 'Sugeneruota galia',
          chartType: 'bar',
          values: [],
        }],
        labels: [],
        yMarkers: [
          {
            label: '',
            value: 0,
            type: 'solid',
          },
        ],
      },
    }
  },
  computed: {
    reservationsActive() {
      const reservationsActive = this
        .query('reservation', {
          isCancelled: false,
          isReserved: true,
          maintenanceStartedAt: { $ne: null },
          userId: this.userIdSelected,
          $sort: { id: 1 },
        })

      const projectIds = this
        .query('project', {
          id: { $in: pluckId('projectId', reservationsActive) },
          plantId: { $ne: null },
        })
        .map(({ id }) => id)

      return reservationsActive
        .filter(({ projectId }) => projectIds.includes(projectId))
    },
    reservationsActiveSince() {
      return Object.fromEntries(this
        .reservationsActive
        .map(reservation => [
          reservation.id,
          reservation.maintenanceStartedAt,
        ]))
    },
    reservationsSelected() {
      return this
        .reservationsActive
        .filter(({ id }) => this
          .reservationIdsSelected
          .includes(id))
    },
    earliestAt() {
      const [earliestTime] = this.plantStatTotalsSelected
        .map(({ dailyEnergySlices }) => dailyEnergySlices.monthly[0].timeframe)
        .filter(Boolean)
        .map(date => new Date(date).getTime())
        .sort()

      if (!earliestTime) return new Date()

      return new Date(earliestTime)

      // previous version
      // return Object
      //   .values(this.reservationsActiveSince)
      //   .sort()
      //   .reverse()[0]
    },
    longTermQuery() {
      return {
        isIntraDay: false,
        plantId: { $in: this.plantIdsSelected },
        recordedAt: {
          $gte: this.startAt,
          $lte: this.endAt,
        },
        $sort: { recordedAt: 1 },
      }
    },
    shortTermQuery() {
      return {
        isIntraDay: true,
        plantId: { $in: this.plantIdsSelected },
        recordedAt: this.shortTermSelected.recordedAt,
        $sort: { recordedAt: 1 },
      }
    },
    plantStatsShortTermWeighted() {
      return this.getPlantStats(this.shortTermQuery, toCapacitiesByMoment)
    },
    plantStatsLongTermWeighted() {
      if (this.timeframe !== 'MONTH') {
        const timeframeKeys = {
          YEAR: 'monthly',
          ALL: 'yearly',
        }

        const toIsoDate = (dateString) => {
          const [year, month = '01'] = dateString
            .split('-')

          return new Date([year, month, '01'].join('-'))
        }

        const timeframeKey = timeframeKeys[this.timeframe]
        const energyStats = this.plantStatTotalsSelected
          .flatMap(({ dailyEnergySlices }) => dailyEnergySlices[timeframeKey])

        const energyStatsGrouped = groupByKey('timeframe', energyStats)

        return Object
          .entries(energyStatsGrouped)
          .map(([timeframe, energyStats]) => ({
            recordedAt: new Date(toIsoDate(timeframe)),
            dailyEnergy: energyStats
              .map(energyStat => energyStat.energy)
              .reduce(intoSum, 0),
          }))
          // between startAt and endAt
          .filter(({ recordedAt }) => {
            const isAfterStartAt = recordedAt >= this.startAt
            const isBeforeEndAt = recordedAt <= this.endAt

            return isAfterStartAt && isBeforeEndAt
          })
      }

      return this.getPlantStats(this.longTermQuery, toCapacitiesByDay)
    },
    projectsActive() {
      const projectsIds = pluckId('projectId', this.reservationsActive)

      return this
        .query('project', { id: { $in: projectsIds } })
    },
    projectsSelected() {
      const projectIds = pluckId('projectId', this.reservationsSelected)

      return this
        .projectsActive
        .filter(({ id }) => projectIds.includes(id))
    },
    plantIdsSelected() {
      return pluckId('plantId', this.projectsSelected)
    },
    plantStatTotalsSelected() {
      return this
        .plantStatTotals
        .filter(plantStatTotal => this
          .reservationIdsSelected
          .includes(plantStatTotal.reservationId))
    },
    keyPoints() {
      if (!this.plantStatsShortTermWeighted) return

      const powerTotal = this
        .plantStatTotalsSelected
        .map(plantStatTotal => plantStatTotal.dailyEnergySlices.total)
        .reduce(intoSum, 0)

      const DAY_MS = 24 * 60 * 60 * 1000

      // const powerDaily = this.getPlantStats({
      //   isIntraDay: false,
      //   plantId: { $in: this.plantIdsSelected },
      //   recordedAt: { $gte: getRelativeDate(-DAY_MS) },
      // }, toCapacitiesByMoment)
      //   .map(plant => plant.dailyEnergy)
      //   .reduce(intoSum, 0)

      const [plantStat] = this.getPlantStats({
        isIntraDay: true,
        plantId: { $in: this.plantIdsSelected },
        recordedAt: { $gte: getRelativeDate(-DAY_MS) },
      }, toCapacitiesByMoment)
        .slice(-1)

      const powerCurrent = propOr(0, 'power', plantStat)

      const CO2_RATIO = 1.003018
      const co2Saved = (powerTotal / CO2_RATIO) || 0

      const format = (suffix, value) => `${commaDecimal(value)} ${suffix}`

      return [
        {
          icon: 'power-lines',
          label: 'Pagaminta energija',
          value: format('kWh', powerTotal),
        },
        // {
        //   icon: 'bolt-beam',
        //   label: 'Šiandienos energija',
        //   value: format('kWh', powerDaily),
        // },
        {
          icon: 'solar-panel',
          label: 'Dabartinė energija',
          value: format('kW', powerCurrent),
        },
        {
          icon: 'leaf',
          label: 'Sutaupytas CO2',
          value: format('t', co2Saved),
        },
      ]
    },
    userIdSelected() {
      const authUserId = this.$store.getters.authUser.id

      if (!this.userSelectedEmail) return authUserId

      const userByEmail = this
        .query('user', { email: this.userSelectedEmail })[0]

      return prop('id', userByEmail) || authUserId
    },
    ...getters(),
  },
  methods: {
    getPlantStats(query, toCapacities) {
      const plantStatsRaw = this.query('plantStat', query)

      const plantStatsSharedByProjects = this.projectsSelected
        .map(projectSelected => toPlantStatsSharedByProjects(projectSelected, plantStatsRaw))

      // calculate by reservations
      const plantStatsSharedByReservations = this.reservationsSelected
        .map(reservationSelected => ({
          reservationId: reservationSelected.id,
          plantStats: (plantStatsSharedByProjects
            .find(plantStatsSharedByProject =>
              plantStatsSharedByProject.projectId === reservationSelected.projectId))
            .plantStatsCalculated,
        }))
        // sorted for first reservation in array to have the most plantStats
        .sort((a, b) => a.plantStats.length - b.plantStats.length)

      const plantStatsSharedByReservationsWeighted = plantStatsSharedByReservations
        .map(plantStatsShortTermSharedByReservation => this
          .weighReservationPlantStats(plantStatsShortTermSharedByReservation, toCapacities))

      return combinePlantStats(plantStatsSharedByReservationsWeighted)
    },
    weighReservationPlantStats(reservationPlantStats, toCapacities) {
      const reservation = this.reservationsActive
        .find(reservationActive => reservationActive.id === reservationPlantStats.reservationId)
      const project = this.projectsActive
        .find(projectActive => projectActive.id === reservation.projectId)

      const weighted = this.weighPlantStats(
        toCapacities(this.reservationsActiveSince),
        project,
        reservation,
        reservationPlantStats.plantStats,
      )

      return {
        reservationId: reservation.id,
        plantStats: weighted,
      }
    },
    formatReadingPower(power) {
      return `${commaDecimal(power)} kW`
    },
    formatReadingPowerTotal(power) {
      return `${commaDecimal(power)} kWh`
    },
    fetchPlantStats(plantStatQuery) {
      if (pathEq(['plantId', '$in', 'length'], 0, plantStatQuery)) return []

      return this.FETCH_PLANT_STAT(plantStatQuery)
    },
    formChartData: (() => {
      return function(toLabel, plantStatsWeighted, chart, doShowOnlyRelevantData) {
        if (!this.plantIdsSelected.length) {
          return formatPlantStats(toLabel, [], chart)
        }

        return formatPlantStats(
          toLabel,
          plantStatsWeighted,
          chart,
          doShowOnlyRelevantData,
        )
      }
    })(),

    weighPlantStats: (() => {
      const keysToWeigh = ['dailyEnergy', 'power']

      const getCapacityRatio = (toCapacities, project, reservation, plantStat) =>
        toCapacities(reservation, plantStat)
          .reduce(intoSum, 0) / project.capacity

      // NOTE: consider optimizing since it's run a lot
      return function(toCapacities, project, reservation, plantStats) {
        return plantStats
          .map(plantStat => ({
            ...plantStat,
            ...Object.fromEntries(keysToWeigh
              .map(key => [
                key,
                plantStat[key] * getCapacityRatio(
                  toCapacities,
                  project,
                  reservation,
                  plantStat,
                ),
              ])),
          }))
      }
    })(),

    async fetchUpdateChartShortTerm() {
      await this.fetchPlantStats(this.shortTermQuery)
      setTimeout(this.updateChartShortTerm, 500)
    },
    async fetchUpdateChartLongTerm() {
      await this.fetchPlantStats(this.longTermQuery)
      setTimeout(this.updateChartLongTerm, 500) // needed to stagger chart updates
    },
    updateChartShortTerm: (() => {
      const toHoursMinutes = ({ recordedAt }) => [
        recordedAt
          .getHours()
          .toString()
          .padStart(2, '0'),
        recordedAt
          .getMinutes()
          .toString()
          .padStart(2, '0'),
      ].join(':')

      return async function() {
        if (!this.plantStatsShortTermWeighted) return

        this.shortTerm = await this.formChartData(
          toHoursMinutes,
          this.plantStatsShortTermWeighted,
          this.shortTerm,
          this.doShowOnlyRelevantData,
        )
      }
    })(),
    updateChartLongTerm: (() => {
      const withProp = (propName, method) => object =>
        method(object[propName])

      const labelsByTimeframe = {
        YEAR: date =>
          monthDate(date, false)
            .split(' ')[0],
        MONTH: date => date
          .getDate()
          .toString(),
        ALL: date => date
          .getFullYear()
          .toString(),
      }

      return async function() {
        this.longTerm = await this.formChartData(
          withProp('recordedAt', labelsByTimeframe[this.timeframe]),
          this.plantStatsLongTermWeighted,
          this.longTerm,
          true, // this.doShowOnlyRelevantData
        )
      }
    })(),

    async fetchAndSetUserStats() {
      const [plantStatTotals] = await Promise.all([
        this.FIND_PLANT_STAT_TOTAL({
          userId: this.userIdSelected,
        }),
        this.fetchUpdateChartLongTerm,
        this.fetchUpdateChartShortTerm,
      ])

      this.plantStatTotals = plantStatTotals
    },

    setAllReservationsSelected() {
      this.reservationIdsSelected = this
        .reservationsActive
        .map(reservation => reservation.id)
    },

    ...actions(
      'FETCH_PLANT_STAT',
      'FIND_PLANT_STAT_TOTAL',
    ),
  },
  watch: {
    longTermQuery: 'fetchUpdateChartLongTerm',
    shortTermQuery: 'fetchUpdateChartShortTerm',
    doShowOnlyRelevantData: 'updateChartShortTerm',
    reservationIdsSelected: ['updateChartShortTerm', 'updateChartLongTerm'],
    plantStatsShortTermWeighted(plantStats) {
      if (!plantStats.length) return

      this.updateChartShortTerm()
    },
    userIdSelected: [
      'setAllReservationsSelected',
      'fetchAndSetUserStats',
    ],
  },
  async mounted() {
    this.setAllReservationsSelected()
    await this.fetchAndSetUserStats()
  },
}
</script>

<style lang="scss">
#ReservationStats {
  .Chart.realTime {
    .dataset-units.dataset-line.dataset-0 {
      circle {
        display: none;

        &:last-of-type {
          animation-duration: 3s;
          animation-iteration-count: infinite;
          animation-name: pulse2;
          display: block;
          stroke: $blue;
          transform-box: fill-box;
          transform-origin: 50% 50%;
        }
      }
    }
  }

  .disclosure {
    text-align: center;
  }
}

@keyframes pulse2 {
  0% {
    fill: $blue;
    stroke-width: 2;
    transform: scale3d(1, 1, 1);
  }

  50% {
    fill: desaturate(lighten($blue, 10%), 10%);
    stroke-width: 3;
    transform: scale3d(1.4, 1.4, 1.4);
  }

  100% {
    fill: $blue;
    stroke-width: 2;
    transform: scale3d(1, 1, 1);
  }
}
</style>
