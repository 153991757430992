<template>
  <div class="ReservationSelect">
    <Btn
      v-for="reservation in reservationsWithActivity"
      :key="reservation.id"
      class="ib"
      :class="{ inv: !doHighlight(reservation.id) }"
      @click="setValue(reservation.id)"
      @keypress.enter="setValue(reservation.id)">
      <div class="label">
        <strong>
          {{ reservation.capacity | kw }}
        </strong>
        <small>
          {{ reservation.project.name }}
        </small>
      </div>
    </Btn>
  </div>
</template>

<script>
import { getters, intoSum } from 'views/utils'

export default {
  props: {
    reservations: Array,
    value: Array,
  },
  computed: {
    reservationsWithActivity() {
      return [
        ...(this.reservations.length > 1
          ? [{
            id: null,
            capacity: this
              .reservations
              .map(reservation => reservation.capacity)
              .reduce(intoSum, 0),
            project: { name: 'Visi užsakymai' },
            isActive: this.value.length > 1,
          }]
          : []),
        ...this
          .reservations
          .map(reservation => ({
            ...reservation,
            isActive: this.isActive(reservation.id),
            project: this.query('project', reservation.projectId),
          })),
      ]
    },
    ...getters(),
  },
  methods: {
    doHighlight(reservationId) {
      // if only one reservation exists
      if (this.reservations.length === 1) return true

      if (this.value.length === this.reservations.length && reservationId === null) return true

      if (this.value.length === this.reservations.length) return false

      if (this.value.includes(reservationId)) return true

      return false
    },
    isActive(reservationId) {
      return this.value.length === 1 && this.value[0] === reservationId
    },
    setValue(reservationId) {
      this.$emit('update:value', ((reservationId, reservationIdsSelected, allReservations) => {
        const allReservationIds = allReservations
          .map(reservation => reservation.id)

        if (reservationId === null) return allReservationIds

        if (reservationIdsSelected.includes(reservationId)) {
          if (reservationIdsSelected.length === allReservations.length) return [reservationId]

          if (reservationIdsSelected.length === 1) return allReservationIds

          return reservationIdsSelected.filter(value => value !== reservationId)
        }

        return [
          ...reservationIdsSelected,
          reservationId,
        ]
      })(reservationId, this.value, this.reservations))
    },
  },
}
</script>

<style lang="scss">
.ReservationSelect {
  margin: $grid-unit 0;

  > h4 {
    margin-bottom: $grid-unit;
  }

  .Btn {
    align-items: center;
    border-radius: 30px;
    display: inline-flex;
    line-height: $lh * 1.5;
    margin-right: $grid-unit;
    padding-left: $grid-unit;
    padding-right: $grid-unit;

    .Icon {
      font-size: $h4;
      margin-right: 1rem;
      vertical-align: middle;
    }

    > .label {
      display: inline-block;

      > strong {
        display: block;
        font-size: $h3;
        line-height: 1.5rem;
        margin-top: 0.5rem;
        text-align: right;
      }
    }

    &.isActive {
      cursor: default;
    }

    &:hover,
    &:focus,
    &:active {
      background: $blue;
    }

    &.inv {
      &:hover,
      &:focus,
      &:active {
        background: inherit;
        color: $main;
      }
    }
  }
}
</style>
