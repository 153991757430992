<template>
  <CardFancy class="Reservation" :title="project.name">
    <EsoObjectModal
      :isOpen.sync="isEsoModalOpen"
      :reservation="reservation"
      @close="isEsoModalOpen = false" />
    <Row>
      <Column :xs="6">
        <Set class="lg" icon="bolt" name="Rezervuota galia">
          {{ reservation.capacity | kw }}
        </Set>
      </Column>
      <Column :xs="6">
        <Set class="lg" icon="euro" name="Pirkimo kaina su PVM">
          {{ reservation.priceTotal }} €/kW
        </Set>
      </Column>
      <Column :xs="6">
        <Set class="lg" icon="clock" name="Rezervavimo data">
          {{ reservation.createdAt | date }}
        </Set>
      </Column>
      <Column :xs="6">
        <Set class="lg" icon="hard-hat" name="Metinė priežiūra">
          {{ reservation.maintenanceAnnualTotal | commaDecimal }} €
        </Set>
      </Column>
    </Row>
    <Tag v-if="reservation.isCancelled" class="danger">
      Atšauktas
    </Tag>
    <div slot="actions">
      <Row class="actions">
        <Column class="action" :md="6">
          <Route :id="project.id" class="Btn arrow" to="SelfProjectProgress">
            Projekto eiga
          </Route>
        </Column>
        <Column class="action" :md="6">
          <Route :id="reservation.id" class="Btn arrow" to="Reservation">
            Užsakymo informacija
          </Route>
        </Column>
        <!-- <Column class="action" :md="4">
          <Btn :id="reservation.id" class="Btn arrow" @click="showEsoModal">
            Vartojimo objektas
          </Btn>
        </Column> -->
      </Row>
    </div>
  </CardFancy>
</template>

<script>
import CardFancy from './CardFancy'
import EsoObjectModal from './EsoObjectModal'
import Set from './Set'
import { getters } from '../utils'

export default {
  components: { CardFancy, EsoObjectModal, Set },
  props: {
    reservation: Object,
  },
  data() {
    return {
      isEsoModalOpen: false,
    }
  },
  computed: {
    project() {
      return this.query('project', this.reservation.projectId)
    },
    ...getters(),
  },
  methods: {
    showEsoModal() {
      this.isEsoModalOpen = true
    },
  },
}
</script>

<style lang="scss">
.CardFancy.Reservation {
  > .actions {
    margin-top: 1.5rem;

    .action {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
