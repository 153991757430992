<template>
  <div class="StatUserEmail">
    <Btn
      class="inv"
      @click="doShow = !doShow">
      Rodyti
      {{ doShow
        ? 'mano statistiką'
        : 'kito vartotojo statistiką'
      }}
    </Btn>
    <div v-if="doShow">
      <Field
        label="Vartotojo el. paštas"
        placeholder="vardenis@pavardenis.lt"
        :value="emailLocal"
        @update:value="setEmail" />
      <Notice
        v-if="user"
        class="success">
        Rodomas vartotojas: {{ user | name }}
      </Notice>
    </div>
  </div>
</template>

<script>
import { actions, getters } from '../utils'

export default {
  props: {
    email: String,
  },
  data() {
    return {
      doShow: false,
      fetchTimeout: null,
      emailLocal: null,
    }
  },
  computed: {
    user() {
      if (!this.email) return

      return this
        .query('user', { email: this.email })[0]
    },
    ...getters(),
  },
  methods: {
    async setEmail(email) {
      clearTimeout(this.fetchTimeout)
      this.emailLocal = email

      // empty field
      if (!email) {
        this.$emit('update:email', email)
        return
      }

      const isValid = email.includes('@') &&
        email.includes('.') &&
        email.split('.')[1].length > 1

      if (!isValid) return

      this.fetchTimeout = setTimeout(
        async () => {
          const users = await this.FIND_USER({ email })

          if (!users.length) return

          const userId = users[0].id

          await this.FETCH_RESERVATION({ userId })

          this.$emit('update:email', email)
        },
        300,
      )
    },
    ...actions(
      'FETCH_RESERVATION',
      'FIND_USER',
    ),
  },
}
</script>

<style lang="scss">
.StatUserEmail {
}
</style>
