<template>
  <div class="ChartYearMonth">
    <div>
      <Tab
        v-for="{ label, value } in timeframeOptions"
        :key="label"
        :isActive="timeframe === value"
        @click="setTimeframe(value)">
        {{ label }}
      </Tab>
    </div>
    <Field
      v-if="rangeOptions.length"
      :options="rangeOptions"
      type="select"
      :value="startAt.toISOString()"
      @update:value="setStartAt($event, timeframe)" />
  </div>
</template>

<script>
import { capitalize, endOfMonth, startOfMonth, startOfYear } from 'views/utils'
import Tab from 'components/Tab'
import { getRelativeDate } from 'views/ReservationStats/utils'

export default {
  components: {
    Tab,
  },
  props: {
    earliestAt: Date,
    endAt: Date,
    startAt: Date,
    timeframe: String,
  },
  data() {
    return {
      timeframeOptions: [
        {
          label: 'Mėnuo',
          value: 'MONTH',
        },
        {
          label: 'Metai',
          value: 'YEAR',
        },
        {
          label: 'Viso',
          value: 'ALL',
        },
      ],
    }
  },
  computed: {
    rangeOptions: (() => {
      const range = (start, end) => new Array(end - start)
        .fill()
        .map((_, index) => index + start)

      const offsetDateNegative = (methodSuffix, dateInitial) => (offset) => {
        const date = new Date(dateInitial)
        date[`set${methodSuffix}`](date[`get${methodSuffix}`]() - offset)
        return date
      }

      const getMonthName = (() => {
        const formatter = new Intl.DateTimeFormat('lt', { month: 'long' })
        return date => capitalize(formatter.format(date))
      })()

      // const isSameYear = (dateInitial) => {
      //   const year = dateInitial.getFullYear()

      //   return date => date.getFullYear() === year
      // }

      const gteByMethod = (methodName, date1) => {
        const value1 = date1[methodName]()

        return date2 => date2[methodName]() >= value1
      }

      const yesterday = getRelativeDate(-24 * 60 * 60 * 1000)

      return function() {
        if (this.timeframe === 'ALL') return []

        const dates = (() => {
          if (this.timeframe === 'MONTH') {
            const now = startOfMonth(yesterday)
            const currentMonthIndex = now.getMonth()

            return range(0, currentMonthIndex + 1)
              .map(offsetDateNegative('Month', now))
              // .filter(isSameYear(now))
              // .filter(gteByMethod('getMonth', this.earliestAt))
              .map(date => [date.toISOString(), getMonthName(date)])
          }

          const now = startOfYear(yesterday)
          return range(0, 100)
            .map(offsetDateNegative('FullYear', now))
            .filter(gteByMethod('getFullYear', this.earliestAt))
            .map(date => [date.toISOString(), date.getFullYear()])
        })()

        return dates
          .reverse()
      }
    })(),
  },
  methods: {
    setTimeframe(timeframe) {
      if (timeframe === this.timeframe) return

      const startAt = {
        MONTH: startOfMonth,
        YEAR: startOfYear,
        ALL: () => new Date(2020, 0, 1),
      }[timeframe](new Date())

      this.$emit('update:timeframe', timeframe)
      this.setStartAt(startAt, timeframe) // timeframe has to be passed
    },
    setStartAt: (() => {
      const endOfYear = (date) => {
        const lastMoment = new Date(date)
        lastMoment.setFullYear(lastMoment.getFullYear() + 1, 0, 1)
        lastMoment.setHours(0, 0, 0, -1)
        return lastMoment
      }

      return function(startAt, timeframe) {
        if (typeof startAt === 'string') startAt = new Date(startAt)

        const endAt = {
          MONTH: endOfMonth,
          YEAR: endOfYear,
          ALL: () => new Date(),
        }[timeframe](startAt)

        this.$emit('update:startAt', startAt)
        this.$emit('update:endAt', endAt)
      }
    })(),
  },
}
</script>

<style lang="scss">
.ChartYearMonth {
  display: flex;
  justify-content: space-between;
  padding: $grid-unit $grid-unit 0;
}
</style>
