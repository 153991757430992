import { getRelativeDate } from './utils'
import { startOfDay } from 'views/utils'

export const toCapacitiesByMoment = reservationsActiveSince => (reservation, plantStat) => {
  if (reservationsActiveSince[reservation.id] > plantStat.recordedAt) return []

  return [reservation.capacity]
}

export const toCapacitiesByDay = (() => {
  const getHoursSinceStartOfDay = date => date.getHours() + (date.getMinutes() / 60)

  const getDayRatio = (() => {
    const dailySigmoid = hour =>
      (17.93 / (1 + Math.pow(Math.E, -(0.488 * hour - 6.24)))) / 17.85496

    const getHourRatio = (hour) => {
      if (hour < 5) return 1
      if (hour >= 20) return 0
      return 1 - dailySigmoid(hour)
    }

    return (hourOrdered, hourNow) =>
      getHourRatio(hourOrdered) - getHourRatio(hourNow)
  })()

  return (reservationsActiveSince) => {
    const now = getRelativeDate()
    const nowDateString = now.toLocaleDateString()
    const hoursNow = getHoursSinceStartOfDay(now)
    const reservationsActiveSinceDays = Object.fromEntries(Object
      .entries(reservationsActiveSince)
      .map(([reservationId, activeSince]) => [
        reservationId,
        startOfDay(activeSince),
      ]))

    return ({ id, capacity }, { recordedAt }) => {
      if (reservationsActiveSinceDays[id] > recordedAt) return []
      if (reservationsActiveSince[id] <= recordedAt) {
        return [capacity]
      }

      const isSameDay = reservationsActiveSince[id].toLocaleDateString() === nowDateString
      const hoursPassed = isSameDay ? hoursNow : 24
      const hoursSinceStartOfDay = getHoursSinceStartOfDay(reservationsActiveSince[id])
      const dayRatio = getDayRatio(
        hoursSinceStartOfDay,
        hoursPassed,
      )

      return [capacity * dayRatio]
    }
  }
})()
