<template>
  <div id="SelfServiceInner" :class="isLoaded || 'loading'">
    <Wrap v-if="isLoaded" class="lg">
      <TabsHead
        id="self-service-tabs"
        :active="activeTab"
        class="center"
        :tabs="tabs"
        @update:active="updateRoute">
        <Tag
          v-if="!hasSufficientProfile"
          slot="SelfProfile"
          class="danger sm">
          !
        </Tag>
        <span v-if="unseenMessagesCount" slot="SelfMessages">
          <Tag class="danger sm">{{ unseenMessagesCount }}</Tag>
        </span>
      </TabsHead>
      <router-view />
    </Wrap>
    <div v-else class="splash">
      <Loader />
    </div>
  </div>
</template>

<script>
import { capitalize, getters } from 'views/utils'
import Meta from '../mixins/Meta'
import TabsHead from '../components/TabsHead'
import { pluckId } from 'views/utils/collections'

export default {
  mixins: [
    Meta('Savitarna'),
  ],
  components: { TabsHead },
  computed: {
    activeTab() {
      return this.tabs
        .find(({ value }) => value === this.$route.name)
    },
    hasStats() {
      if (this.hasRole('>=ADMIN')) return true

      const reservations = this
        .query('reservation', {
          isCancelled: false,
          isReserved: true,
          maintenanceStartedAt: { $ne: null },
          userId: this.authUser.id,
        })

      return this
        .query('project', {
          id: { $in: pluckId('projectId', reservations) },
        })
        .some(project => project.plantId)
    },
    tabs() {
      return [
        {
          name: this.joinOnDesktop('Saulės', 'elektrinės'),
          value: 'SelfProjects',
        },
        {
          name: this.joinOnDesktop('Mano', 'užsakymai'),
          value: 'SelfReservations',
        },
        ...this.hasStats
          ? [{
            name: 'Statistika',
            value: 'ReservationStats',
          }]
          : [],
        {
          name: this.joinOnDesktop('Mano', 'duomenys'),
          props: {
            'data-cy': 'editProfile',
          },
          value: 'SelfProfile',
        },
        {
          name: 'Pranešimai',
          value: 'SelfMessages',
        },
        ...(this.isDesktop
          ? [{
            name: 'Atsijungti',
            value: 'Logout',
          }]
          : []),
      ]
    },
    unseenMessagesCount() {
      return this.query('message', {
        isSeen: false,
        userId: this.authUser.id,
      }).length
    },
    ...getters('hasSufficientProfile', 'isDesktop'),
  },
  methods: {
    joinOnDesktop(...words) {
      const displayedWords = this.isDesktop
        ? words
        : words.slice(1)

      return capitalize(displayedWords.join(' '))
    },
    updateRoute(routeName) {
      if (this.$route.name === routeName) return

      this.$router.push({ name: routeName })
    },
  },
}
</script>

<style lang="scss">
#SelfServiceInner {
  height: 100%;
  // margin-top: 2rem;
  min-height: 100%;
  width: 100%;

  // .routerLink {
  //   border-bottom: inherit;
  // }
  // .routerLink {
  //   border-bottom: 2px solid $black !important;
  // }

  h2 {
    margin-bottom: 2rem;
  }

  .splash {
    align-items: center;
    display: flex;
    height: 45%;
    position: absolute;
    width: 100%;

    > .Loader {
      border-left-color: $main;
      color: rgba($main, 0.2);
      font-size: 3rem;
    }
  }
}

#self-service-tabs {
  margin-bottom: 1.5rem;
}
</style>
