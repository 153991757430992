<template>
  <Sect class="SelfProjects">
    <!-- <NoticeGreeting v-if="!userHasReservations" /> -->
    <!-- <transition-group appear name="slide-fade">
      <Project
        v-for="project in projects"
        :key="project.id"
        :project="project" />
    </transition-group> -->
    <div class="projects">
      <CardProject
        :doShowReservations="true"
        :project="projectMain" />
      <Row>
        <Column
          v-for="project in projectsWithReservations"
          :key="project.id">
          <CardProject
            :doShowReservations="true"
            :project="project" />
        </Column>
      </Row>
      <Row>
        <Column
          v-for="project in projectsWithoutReservations"
          :key="project.id"
          :lg="6">
          <CardProject
            :doShowReservations="true"
            :project="project" />
        </Column>
      </Row>
    </div>
    <!-- <transition appear name="slide-fade">
      <CardSubscribe />
    </transition> -->
    <CardFancy class="help lg blank" title="Turite klausimų?">
      <Row>
        <Column :lg="4">
          <Route class="Btn arrow inverse" to="HowItWorks">
            Kaip vyksta elektrinės užsakymas?
          </Route>
        </Column>
        <Column :lg="4">
          <Route class="Btn arrow inv" to="Faq">
            Dažniausiai Užduodami Klausimai
          </Route>
        </Column>
        <Column :lg="4">
          <Route class="Btn arrow inv" to="Contact">
            Susisiekite
          </Route>
        </Column>
      </Row>
      <div />
    </CardFancy>
    <Route v-if="!isDesktop" class="Btn arrow minimalistic" to="Logout">
      Atsijungti
    </Route>
  </Sect>
</template>

<script>
import CardFancy from './components/CardFancy'
import CardProject from 'views/components/CardProject'
// import CardSubscribe from './components/CardSubscribe'
// import NoticeGreeting from './components/NoticeGreeting'
// import Project from './components/Project'
// import ProjectMocker from 'mixins/ProjectMocker'
import ProjectBundler from 'mixins/ProjectBundler'
import { getters } from './utils'

export default {
  mixins: [
    ProjectBundler,
  ],
  components: { CardProject, CardFancy },
  computed: {
    userReservations() {
      const reservations = this
        .query('reservation', {
          userId: this.authUser.id,
          $select: ['projectId'],
        })

      return reservations.map(reservation => reservation.projectId)
    },
    projectsWithReservations() {
      return this.projectsWithoutMain
        .filter(project => this.userReservations.includes(project.id))
    },
    projectsWithoutReservations() {
      return this.projectsWithoutMain
        .filter(project => !this.projectsWithReservations
          .some(projectWithoutReservation => projectWithoutReservation.id === project.id))
    },

    // projects() {
    //   return this.query('project', {
    //     isPublic: true,
    //     $sort: {
    //       order: 1,
    //     },
    //   })
    // },
    ...getters('isDesktop'),
  },
  methods: {
    hasUserReservationsInProject(projectId) {
      return this.userReservations
        .some(reservation => reservation.projectId === projectId)
    },
  },
}
</script>

<style lang="scss">
.SelfProjects {
  .CardProject {
    background: $white-ter;
    margin-bottom: 2rem;
  }
}

.CardFancy.help {
  margin: 2rem 0 0;
  padding: 0;
  @include lg {
    padding-bottom: 1rem;
  }

  .title {
    text-align: center;
  }

  .Btn {
    margin-bottom: 1rem;
  }
}
</style>
