<template>
  <RadioTabs
    class="FieldCustomAdvancePayment"
    :disabled="disabled"
    label="Avanso dydis"
    :value="value"
    @update:value="$up('value', $event)">
    <div
      slot="false"
      data-cy="schedule-RESERVATION"
      label="Standartinis dydis"
      :value="false">
      Mokėti {{ amountDefault }}% avansą.
    </div>
    <div
      slot="true"
      data-cy="schedule-ADVANCE"
      label="Individualus avanso dydis"
      :value="true">
      Nustatyti individualų avanso dydį ({{ range[0] }}-{{ range[1] }}%)
    </div>
  </RadioTabs>
</template>

<script>
import RadioTabs from 'components/fields/RadioTabs'

export default {
  components: { RadioTabs },
  props: {
    value: Boolean,
    disabled: Boolean,
    amountDefault: Number,
    range: Array,
  },
}
</script>

<style lang="scss">
.FieldCustomAdvancePayment {
  margin-bottom: $grid-unit;
}
</style>
