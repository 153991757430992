<template>
  <Sect>
    <transition-group appear name="slide-fade">
      <Message
        v-for="message in messages"
        :key="message.id"
        :message="message" />
    </transition-group>
    <Empty v-if="!messages.length">
      Neturite jokių pranešimų.
    </Empty>
  </Sect>
</template>

<script>
import Message from './components/Message'
import { getters } from './utils'

export default {
  components: { Message },
  computed: {
    messages() {
      return this.query('message', { userId: this.authUser.id })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>
