<template>
  <Sect>
    <transition-group appear name="slide-fade">
      <Reservation
        v-for="reservation in reservations"
        :key="reservation.id"
        :reservation="reservation" />
    </transition-group>
    <Empty v-if="!reservations.length">
      Neturite jokių užsakymų. Juos galite pradėti
      <Route to="SelfProjects">
        Saulės elektrinės
      </Route>
      sekcijoje.
    </Empty>
  </Sect>
</template>

<script>
import Reservation from './components/Reservation'
import { getters } from './utils'

export default {
  components: { Reservation },
  computed: {
    contracts() {
      if (!this.reservations.length) return []

      return this.query('contract', {
        reservationId: this.reservations[0].id,
      })
    },
    reservations() {
      return this.query('reservation', {
        userId: this.authUser.id,
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.CardFancy.help {
  .title {
    text-align: center;
  }
}
</style>
